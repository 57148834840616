import React from 'react';

function Footer() {
    return (
        <footer>
        <div className='footer '>
          <div className='footer-txt '>
            <div>کپی رایت</div>
          </div>
          </div>
      </footer>
    );
}

export default Footer;