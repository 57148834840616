export const navLinks = [
    { id: 1, label: 'خانه', href: '/' },
    { id: 2, label: 'دوره ها', href: 'courses' },
    { id: 3, label: 'نمونه کارها', href: 'samples' },
    { id: 4, label: 'سرویس ها', href: 'services' },
    { id: 5, label: 'درباره ما', href: 'about-us' },
    { id: 6, label: 'تماس با ما', href: 'contact-us' },
]
export const statistics = [
    { id: 1, label: 'طرح ها', value: 130 },
    { id: 2, label: 'نمونه کارها', value: 95 },
    { id: 3, label: 'سرویس ها', value: 57 }
]
export const slideImg = [
    { id: 1, src: './img/1p.png' },
    { id: 2, src: './img/2p.png' },
    { id: 3, src: './img/3p.png' },
]

export const cards = [
    { id: 20, title: 'لورم متن ساختکی', subTitle: 'طراحی سایت', logo: 'PHP', iconUrl: './img/icon2.png', description: 'طراحان سایت هنگام طراحی قالب سایت معمولا با این موضوع رو برو  روزنامه و مجله در ستون و سطرآنچنان که لازم است هستند که محتوای اصلی صفحات آماده نیست.' },
    { id: 10, title: 'لورم ایپسوم', subTitle: 'تولید متن', logo: 'JS', iconUrl: './img/icon1.png', description: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز  متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز استفاده از طراحان گرافیک است.' },
    { id: 30, title: 'لورم فارسی', subTitle: 'تولید محتوا', logo: 'HTML', iconUrl: './img/icon1.png', description: 'چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز ابزارهای کاربردی می باشد.' },
    { id: 25, title: 'لورم متن ساختکی', subTitle: 'طراحی سایت', logo: 'PHP', iconUrl: './img/icon2.png', description: 'طراحان سایت هنگام طراحی قالب سایت معمولا با این موضوع رو برو  روزنامه و مجله در ستون و سطرآنچنان که لازم است هستند که محتوای اصلی صفحات آماده نیست.' },
    { id: 40, title: 'متن سادگی لورم', subTitle: 'متن فارسی', logo: 'CSS', iconUrl: './img/icon2.png', description: 'متنی ساختگی و بدون معنی است که برای امتحان فونت و یا پر کردن فضا در یک طراحی گرافیکی و یا صنعت چاپ استفاده میشود.' },
    { id: 21, title: 'لورم متن ساختکی', subTitle: 'طراحی سایت', logo: 'PHP', iconUrl: './img/icon2.png', description: 'طراحان سایت هنگام طراحی قالب سایت معمولا با این موضوع رو برو  روزنامه و مجله در ستون و سطرآنچنان که لازم است هستند که محتوای اصلی صفحات آماده نیست.' },
    { id: 31, title: 'لورم فارسی', subTitle: 'تولید محتوا', logo: 'HTML', iconUrl: './img/icon1.png', description: 'چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز ابزارهای کاربردی می باشد.' },
    { id: 22, title: 'لورم متن ساختکی', subTitle: 'طراحی سایت', logo: 'PHP', iconUrl: './img/icon2.png', description: 'طراحان سایت هنگام طراحی قالب سایت معمولا با این موضوع رو برو  روزنامه و مجله در ستون و سطرآنچنان که لازم است هستند که محتوای اصلی صفحات آماده نیست.' },
    
    { id: 35, title: 'لورم فارسی', subTitle: 'تولید محتوا', logo: 'HTML', iconUrl: './img/icon1.png', description: 'چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز ابزارهای کاربردی می باشد.' },
]
export const managers = [
    { id: 1, name: 'متن فارسی', imgUrl: './img/avatar1.png', expertise: 'CEO' , bio: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است' },
    { id: 3, name: 'لورم ایپسوم', imgUrl: './img/avatar2.jpg', expertise: 'FRONT-END DEVELOPER' , bio: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز' },
    { id: 2, name: 'لورم فارسی', imgUrl: './img/avatar2.jpg', expertise: 'BACK-END DEVELOPER' , bio: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است '},
]
export const skills = [
    { id: 100, title: 'java script', level: 60, description: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ار گیرد.' },
    { id: 200, title: 'html', level: 100, description: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.' },
    { id: 300, title: 'css', level: 100, description: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون اساسا مورد استفاده قرار گیرد.' },
    { id: 400, title: 'react js', level: 90, description: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای استفاده قرار گیرد.' },
    { id: 500, title: 'php', level: 20, description: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.' },
    { id: 600, title: 'java ', level: 40, description: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ار گیرد.' },
]